import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { CONTENT_BASEURL_V7 } from '@d-utils/base-urls';
import { environment } from '@d-environments/environment';
import { IApiKeyModel } from '@d-models/apki-key.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiKeyService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  async updateApiKey() {
    const existingApiKey = this.localStorageService.getApiKey();
    if (!existingApiKey) {
      const url = `${CONTENT_BASEURL_V7}/apikey/${environment.MASTER_API_KEY}/country/${environment.DEFAULT_COUNTRY}`;
      const res = await lastValueFrom(this.httpClient.get<IApiKeyModel>(url));
      if (res.apiKey) {
        this.localStorageService.setApiKey(res.apiKey);
      }
    }
  }
}
