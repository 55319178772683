import { Component } from '@angular/core';
import { SpinnerHandlerService } from 'app/shared/services/spinner-handler.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
  spinnerActive = true;

  constructor(
    public spinnerHandler: SpinnerHandlerService,
    private spinner: NgxSpinnerService
  ) {
    this.spinnerHandler.showSpinner.subscribe(this.toggleSpinner.bind(this));
  }

  toggleSpinner = (show: boolean): void => {
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  };
}
