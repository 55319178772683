export const ErrorMessages: any = {
  10000: 'OK',
  10001: 'OK but the language specified was invalid',
  10002: 'OK but this product has already been redeemed',
  10003: 'OK and you used a temporary password',
  10004: 'The voucher code used was invalid but the rest of the request was OK',
  10005: 'OK but a non-vital email failed to send',
  10006: 'Product Redeemed',
  10007: 'Product failed to redeem but the overall request was successful',
  10008: 'Legals acceptance is required',
  10010: 'An invalid notification message id was used',
  10011: "OK but you've already redeemed this voucher code",
  10012: 'Some of the items supplied failed.',
  10013: 'OK but this product is already being rented',
  10014: 'OK this user has accepted all required legals.',
  10015: 'Ok but clearing the cache failed',
  10016: 'Ok but did not send email as is an Instore account',
  10017: 'User not verified',
  19999: 'Used for knowing where the end of the OK codes are',
  20000: 'Unknown API failure',
  20001: 'Unknown error relating to whitelabels',
  20002: 'Unknown error relating to campaign groups',
  20003: 'Unknown error relating to devices',
  20004: 'Unknown error relating to consumers',
  20005: 'Unknown error relating to Sessions',
  20006: 'Server Error: Failed to generate',
  20007: 'The Parent Product specified has not been assigned to this campaign group',
  20008: 'Server Error: There was an error with your session',
  20009: 'Registration Failed',
  20010: 'Failed to register consumer device',
  20011: 'Failed to update download state',
  20012: 'Failed to send vital email',
  20013: 'Failed to redeem voucher',
  20014: 'Server Error: Failed to check session',
  20015: 'DEPRICATED: Server Error: SPDID secrets have not been setup',
  20016: 'Server Error: Unknown error relating to themes',
  20017: 'Server Error: Unknown error relating to White Label Campaigns',
  20018: 'Server Error: Promo state not found',
  20019: 'Software version not found for this device',
  20020: 'No device membership was found for this device',
  30000: 'DEPRICATED: Server Error: The Third Party is not active',
  30001: 'Invalid API Key Supplied',
  30002: 'Facebook login is not enabled for this promotion',
  30003: 'This campaign does not support video unlimited',
  30004: 'Invalid Drm Info Token Supplied',
  40001: 'Invalid session supplied',
  40002: 'Invalid Temporary Passsword supplied',
  40003: 'The password is invalid',
  40004: 'The Voucher code supplied is invalid',
  40005: 'The username or password supplied is incorrect',
  40006: 'The download request code supplied is invalid',
  40007: 'The license request code supplied is invalid',
  40008: 'The email format provided is invalid',
  40009: 'The password format supplied is invalid',
  40010: 'The country code specified was invalid',
  40011: 'Your consumer session has expired',
  40012: 'Device Id is required',
  40013: 'The privacy policy was declined',
  40014: 'The terms and conditions was declined',
  40015: 'The age was not supplied or the checkbox was not checked.',
  40016: 'An account already exists with this username',
  40017: 'A voucher code is required',
  40018: 'The voucher code specified was invalid',
  40019: 'The voucher code specified has expired',
  40020: 'The voucher code specified has already been used',
  40021: 'You do not have enough credits for this redemption',
  40022: 'The device model is required on this endpoint',
  40023: 'Server Error: Failed to redeem product',
  40024: 'The two passwords supplied do not match',
  40025: 'No content was found for the given request',
  40026: 'The redemption is invalid',
  40027: 'Server Error: Unable to perform an automatic redemption',
  40028: 'Delivery type is not allowed for this product',
  40029: 'You do not have the product redeemed',
  40030: 'DEPRICATED: The purchase location used is not available',
  40031: 'DEPRICATED: The child product specified is not active',
  40032: 'You have exceeded your download limit',
  40033: 'The device being used is not a primary device, which is required for this request',
  40034: 'Voucher redemption is not currently available for this campaign',
  40035: 'This promotion is currently unavailable',
  40036: 'Movie credit redemption is not currently available',
  40037: 'Download is not currently available',
  40038: 'We could not find a valid country for your IP',
  40039: 'DEPRICATED: Content path is unavailable',
  40040: 'DEPRICATED: SPDID is required for this endpoint',
  40041: 'DEPRICATED: THe SPDID specified was invalid',
  40042: 'DEPRICATED: The SPDID specified has expired',
  40043: 'DEPRICATED: SPDID Device is invalid',
  40044: 'The Product specified is not currenlty available',
  40045: 'The device specified is not active',
  40046: 'The software version specified',
  40047: 'The consumer has been blocked',
  40048: 'This email has been blocked',
  40049: 'This device has been blocked',
  40050: 'This IP address has been blocked',
  40051: 'Facebook token specified is invalid',
  40052: 'Voucher Rule User Type is invalid',
  40053: 'Content selection type specified is not valid',
  40054: 'The country specified is not valid for this voucher',
  40055: 'The voucher code is not yet active',
  40056: 'The Product is not active',
  40057: 'The Voucher bundle is not active',
  40058: 'This product id does not exist',
  40059: 'Failed to redeem against the specified definition',
  40060: 'The quality level specified is invalid',
  40061: 'The Product could not be redeemed against this definition',
  40062: 'The software version specified is out of date',
  40063: 'Parent Product Ids are required for this endpoint',
  40064: 'The Forced redemption Id specified does not exist',
  40065: 'Definition is required for this endpoint',
  40066: 'Download is disabled for this white label campaign',
  40067: 'The device is not valid for this voucher',
  40068: 'Parent Product Id is required for this endpoint',
  40069: 'Failed to get Email Address from Facebook',
  40070: 'Accepting this legal document is required',
  40071: 'Invalid acceptance format specified',
  40072: 'Account must log in using facebook',
  40073: 'Server Error: Firebase invalid instance Id',
  40074: 'Server Error: Firebase update failed',
  40075: 'Voucher bundle has not started yet',
  40076: 'This voucher code belongs to an alternative promotion',
  40077: 'No static banner found',
  40078: 'No consumer preferences found',
  40079: 'Concurrent stream count reached',
  40081: 'The user has reached their limit of devices on their account',
  40082: 'The consumer device is not authenticated for access',
  40083: 'The consumer must wait an hour before reactivating the device',
  40084: 'Not a valid IP Address',
  40085: 'Requirements not found',
  40086: 'Invalid push notification device specified',
  40087: 'White Label Campaign could not be found',
  40089: "Consumer Profile not found - or doesn't match the ConsumerId",
  40090: 'Access Token has expired, been used, or never existed.',
  40091: 'An invalid ConcurrentStreamEvent has been passed in.',
  40092: "Last Consumer Profile - Can't Delete",
  40093: 'Invalid Characters detected',
  40094: 'Failed To Block any Content',
  40095: 'Failed to Delete any Blocked Content',
  40096: 'Invalid PIN',
  40097: 'Invalid PIN Format',
  40098: 'No PIN Set',
  40099: 'Consumer Profile has expired (a TTL was set)',
  40100: 'Entered Secondary Email already exists for the consumer',
  40101: 'Consumer Profile Has Insufficient Permissions',
  40102: 'Email Already in Use',
  40103: 'Consumer Not Found',
  40104: 'Profile Name exists',
  40105: 'ParentProduct not found in Playlist',
  40106: 'ParentProduct exists in Playlist.',
  40107: 'Can\t Make last profile Kids',

  40108: 'Can\t Expire last profile',
  40109: 'The provided purchase options are invalid (i.e. either both or neither ParentProductId or CreditBundleId were provided)',
  40110: 'Unable to find the parent product (added specifically for purchases but is fairly generic)',
  40111: 'Unable to find the credit bundle (added specifically for purchases but it fairly generic)',
  40112: 'Unable to find the TierPrice',
  40113: 'Unable to find the CreditBundlePrice',
  40114: 'Unable to find the Team',
  40115: 'Cannot set an empty Webhook URL',
  40116: 'Webhook Event could not be found',
  40117: 'Invalid Url Provided',
  40118: 'Movie Track not found',
  40119: 'Movie not found',
  40120: 'Subtitle not found',
  40121: 'Subscription Plan not found',
  40122: 'Subscription not found',
  40123: 'Subscription Invoice not found',
  40124: 'Incorrect Payment State',
  40125: 'RentalPeriod Not found',
  40126: 'RentalPeriod Already exists',
  40127: 'Subscription Already Cancelled',
  40128: 'Content Not Rented',
  40129: 'Renting not supported by WhiteLabelCampaign',
  40130: 'Renting Device limit reached by user',
  40131: 'The Parent Product Definition is not being rented',
  40132: 'The playlist does not have a Custom List Type assigned to it',
  40133: 'No Movies of the Month defined.',
  40134: 'The Category Definition was not found.',
  40135: 'The Category Definition already exists.',
  40136: 'The Series was not found',
  40137: 'The Username was invalid',
  40138: 'Code is locked to a different device',
  40139: 'Playstation Auth code is invalid',
  40140: 'Device model could not be found.',
  40141: 'Playstation: The main account holder must log in and accept legals before a subaccount is allowed to login',
  40142: 'The instore device limit has been reached',
  40143: 'Playstation: The user needs a Playstation Plus Subscription',
  40144: 'The users country is not allowed to login',
  40145: 'The users country is not allowed to register',
  40146: 'The country is inactive for the WhiteLabelCampaign',
  40147: 'The user has exceeded the amount of voucher redemptions allowed for this White Label Campaign',
  40148: 'User option already exist',
  40149: 'User option does not exist',
  40150: 'Option does not exist',
  40151: 'Invalid User Option Response Value',
  40152: 'Option Response does not exist',
  40153: 'Section Type does not exist',
  40154: 'Consumer state has been set to a value other than active, and is not allowed to login',
  40155: 'Invalid WhiteLabelCampaign has been provided, for example during Voucher validation',
  40156: 'The Device model is not supported by the Whitelabel Campaign',
  40157: 'There is no Tier data available',
  40158: 'Request body is empty or failed to deserialize successfully, and null was received as a parameter to an endpoint.',
  40159: 'The collection point specified was not valid or could not be found.',
  40160: 'A provided purpose name could not be found.',
  40161: 'There is already a purchase history record with the provided purchase token',
  40162: 'Product Purchase does not exists',
  40163: 'Consumer already owns the product',
  40164: 'The parent product for the specified video type is unavailable.',
  40165: 'Invalid SKU',
  40166: 'Capability not found',
  40167: 'The user does not have the capability to perform the action.',
  40168: 'The user does not have a high enough device security level',
  40169: 'Invalid User Agent',
  40170: 'TokenExpired',
  40171: 'The payment is not in the correct state to perform the action.',
  40172: 'MFA Verification Failed.',
  40173: 'Missing Alt Video Id',
  40174: 'Missing Tracking Id',
  40175: 'Missing Event',
  40176: 'Refund failed on the Google API',
  40177: 'Invalid Purchase Token',
  40178: 'Invalid Refund Reason',
  40179: 'Invalid Refund Source',
  40180: 'Invalid Package Name',
  40181: 'Device hardware ID is required.',
  40182: 'A transaction ID is required.',
  40183: 'The transaction could not be processed.',
  40184: 'VPN was detected',
  40185: 'Invalid Company Name',
  40186: 'Consumer email is already verified',
  40187: 'Consumer email verification link is expired',
  40188: 'Consumer email verification link does not exists',
  40189: 'Consumer email verification link was already used to verify the account',
  40190: 'Unknown consumer email verification error',
  40191: 'Consumer email verification link duration invalid',
  40192: 'There is a pending transaction.',
  40193: 'The User tried to register using an invalid Domain.',
  40194: 'The studio, or studio pertaining to this parent product, was not found.',
  40195: 'Tier price and currency not match',
  40196: 'Tax amount not match',
  40197: 'Total amount not match',
  40198: 'CyberSource payment not authorized',
  40199: 'CyberSource transaction detail not found',
  40200: 'CyberSource purchase detail not found',
  40201: 'GooglePlay Console purchase detail not found',
  40202: 'CyberSource payment not transmitted',
  40203: 'Transaction is refunded already',
  40204: 'Transaction is voided already',
  40205: 'CyberSource error',
  40206: 'CyberSource invalid request',
  40207: 'CyberSource token verification failed',
  40208: 'CyberSource payment status is not pending for voiding the transaction',
  40209: 'CyberSource refund status is not transmitted for reconciliation',
  40210: 'Provided OAuth token was invalid or unable to be validated.',
  40211: 'Unable to retrieve email address.',
  40212: 'The user has already registered using Google.',
  40213: 'The user has already registered with a username and password.',
  40214: 'The OAuth provider was not recognised or is invalid.',
  40215: 'The account type was invalid for the requested action.',
  40216: 'CyberSource public key not found.',
  40217: 'Void CyberSource payment and revoke the entitlement of the consumer.',
  40218: 'The CastType was not recognised or is invalid.',
  40219: 'Missing required request header - url or cookieme or both.',
  40220: 'HardwareId format is invalid.',
  40221: 'Invalid device.',
  40222: 'Device not entitled to voucher',
  40223: 'Device not entitled to voucher due to launch windows.',
  40224: 'Consumer ranking already exists for the parent product',
  40225: 'Consumer ranking is required as part of this request',
  40226: 'Device preference could not be found.',
  40227: 'Consumer device preference already exists.',
  40228: 'Device preference ID missing from request.',
  40229: 'Device preference value missing from request.',
  40230: 'Device preference value is too long.',
  40231: 'Consumer device preference could not be found.',
  40232: 'Transaction type is invalid',
  40233: 'Bundle is invalid',
  40234: 'Campaign is not active for the given Bundle',
  40235: 'Device Region is not active for the given Bundle',
  40236: 'A single use bundle has already been redeemed by the user',
  40237: 'Redeeming the bundle would violate the subscription length limit',
  40238: 'Bundle is outside availability window',
  40239: 'Bundle is only for first time users and the user is not a first time user',
  40240: 'Record already exists',
  40241: 'Record not found',
  40242: 'Device is not currently active for the given region',
  40243: 'DRM Info Request - DRM signature request failed',
  40244: 'DRM Info Request - No DRM request ID',
  40245: 'DRM Info Request - Duplicate DRM request ID',
  40246: 'DRM Info Request - DRM certification validation failed',
  40247: 'User does not own requested content or their access to it has expired',
  40248: 'CyberSource Flex Micro Form is not supported',
  40249: 'Avalara General Error',
  40250: 'Avalara Server Error',
  40251: 'Avalara Country Not Supported',
  40252: 'Avalara Unknown Address',
  40253: 'Provided state is invalid.',
  40254: 'Provided Notification Message Id is invalid.',
  40255: 'The admin user ID was not found in the auth token',
  40256: 'Invalid Privilege portal admin user ID provided',
  40257: 'Privilege portal admin user ID not found',
  40258: 'Privilege portal admin user ID not found',
  40259: 'User options copy type is invalid',
  40260: 'Avalara transaction is not found or null',
  40261: 'Tax Request Details is not found before connecting to Avalara Endpoints',
  40262: 'Response failure from the Avalara endpoints',
  40263: 'Tax Amount is null',
  40264: 'Data usage request requires data entries',
  40265: 'DataUsageType was invalid',
  40266: 'One or more of the provided data totals is less than the previously submitted value',
  40267: 'Release window not found',
  40268: 'Release window already exists',
  40269: 'Redemption holdback not found',
  40270: 'Redemption holdback already exists',
  40271: 'The user has already registered using PlayStation.',
  40272: 'The user still has credits to redeem.',
  40273: 'The token for the waiting room failed to validate successfully.',
  40274: 'CyberSource payment authorized risk declined.',
  40275: 'CyberSource payment authorized pending review.',
  40276: 'CyberSource consumer token not found.',
  40277: 'Image types supported include jpg, jpeg, and png, while video types supported include mp4 and m4v.',
  40278: 'Unable to upload the file. Please try again.',
};
