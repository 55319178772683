import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MasterLayoutComponent } from './master-layout/master-layout.component';
import { authGuardGuard } from './shared/services/auth-guard.guard';
import { IntroduceComponent } from './master-layout/introduce/introduce.component';
import { EmailVerificationFailedComponent } from './modules/static-page/email-verification-failed/email-verification-failed.component';
import { EmailVerificationSuccessComponent } from './modules/static-page/email-verification-success/email-verification-success.component';
import { HomeComponent } from './master-layout/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: MasterLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
      },
      {
        path: 'introduce/:language/:country',
        component: IntroduceComponent,
      },
      {
        path: 'email-verification/failed/:wlcid/:language/:country',
        component: EmailVerificationFailedComponent,
      },
      {
        path: 'email-verification/success/:wlcid/:language/:country',
        component: EmailVerificationSuccessComponent,
      },
      {
        path: 'account-management',
        canActivate: [authGuardGuard],
        loadChildren: () => import('./modules/account-management/account-management.module').then(m => m.AccountManagementModule),
      },
      {
        path: 'legal',
        loadChildren: () => import('./modules/legal/legal.module').then(m => m.LegalModule),
      },
      {
        path: 'authentication',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
      },
      {
        path: 'voucher',
        canActivate: [authGuardGuard],
        loadChildren: () => import('./modules/voucher/voucher.module').then(m => m.VoucherModule),
      },
      {
        path: 'transaction',
        canActivate: [authGuardGuard],
        loadChildren: () => import('./modules/transaction/transaction.module').then(m => m.TransactionModule),
      },
      {
        path: 'device',
        canActivate: [authGuardGuard],
        loadChildren: () => import('./modules/device-management/device-management.module').then(m => m.DeviceManagementModule),
      },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
