import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '@d-constants/local-storage.enum';
import { ILanguageModel } from '@d-models/languages.model';
import { ILegalContent } from '@d-models/legal.model';
import { IDataOption, IOptionDataModel } from '@d-models/options-data.model';
import { ILocalizationModel } from '@d-models/selected-localization.model';
import { LegalTypes } from '@d-well-know-types/legal-types.enum';
import { BehaviorSubject } from 'rxjs';
import packageInfo from '../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  $SignedInPopup = new BehaviorSubject<boolean>(false);
  $wordingData = new BehaviorSubject<any>(null);
  $localization = new BehaviorSubject<any>(null);
  $legalContents = new BehaviorSubject<any>(null);
  $dataOption = new BehaviorSubject<any>(null);

  getApiKey() {
    return localStorage.getItem(LocalStorageKeys.ApiKey);
  }

  setApiKey(key: string) {
    localStorage.setItem(LocalStorageKeys.ApiKey, key);
  }

  getAvailableCountries() {
    let availableCountries = localStorage.getItem('availableCountries');
    return availableCountries !== null ? (JSON.parse(availableCountries) as string[]) : null;
  }

  setAvailableCountries(countries: string[]) {
    localStorage.setItem('availableCountries', JSON.stringify(countries));
  }

  getLegalContents() {
    const legalObject = localStorage.getItem(LocalStorageKeys.Legals);
    return legalObject !== null ? (JSON.parse(legalObject) as ILegalContent[]) : null;
  }

  setLegalContents(legals: ILegalContent[]) {
    localStorage.setItem(LocalStorageKeys.Legals, JSON.stringify(legals));
    this.$legalContents.next(legals);
  }

  getLegalContentByType(type: LegalTypes) {
    try {
      const legalContent = localStorage.getItem(LocalStorageKeys.Legals);

      if (legalContent) {
        const contentList: ILegalContent[] = JSON.parse(legalContent);
        return contentList.find(c => c.type === type);
      }
    } catch (e) {}
    return null;
  }

  getSession() {
    return this.getSessionOrLocalStorage(LocalStorageKeys.Session);
  }

  setSession(key: string, isRememberMe: boolean = false) {
    if (!isRememberMe) {
      sessionStorage.setItem(LocalStorageKeys.Session, key);
    } else {
      localStorage.setItem(LocalStorageKeys.Session, key);
    }
  }

  getSessionExpiry() {
    return this.getSessionOrLocalStorage(LocalStorageKeys.SessionExpiry);
  }

  setSessionExpiry(key: string, isRememberMe: boolean = false) {
    if (!isRememberMe) {
      sessionStorage.setItem(LocalStorageKeys.SessionExpiry, key);
    } else {
      localStorage.setItem(LocalStorageKeys.SessionExpiry, key);
    }
  }

  getRefreshToken() {
    return this.getSessionOrLocalStorage(LocalStorageKeys.RefreshToken);
  }

  setRefreshToken(key: string, isRememberMe: boolean = false) {
    if (!isRememberMe) {
      sessionStorage.setItem(LocalStorageKeys.RefreshToken, key);
    } else {
      localStorage.setItem(LocalStorageKeys.RefreshToken, key);
    }
  }

  getConsumerId() {
    return this.getSessionOrLocalStorage(LocalStorageKeys.ConsumerId);
  }

  setConsumerId(key: string, isRememberMe: boolean = false) {
    if (!isRememberMe) {
      sessionStorage.setItem(LocalStorageKeys.ConsumerId, key);
    } else {
      localStorage.setItem(LocalStorageKeys.ConsumerId, key);
    }
  }

  getConsumerDeviceId() {
    return this.getSessionOrLocalStorage(LocalStorageKeys.ConsumerDeviceId);
  }

  setConsumerDeviceId(key: string, isRememberMe: boolean = false) {
    if (!isRememberMe) {
      sessionStorage.setItem(LocalStorageKeys.ConsumerDeviceId, key);
    } else {
      localStorage.setItem(LocalStorageKeys.ConsumerDeviceId, key);
    }
  }

  getEmail() {
    return this.getSessionOrLocalStorage(LocalStorageKeys.Email);
  }

  setEmail(key: string, isRememberMe: boolean = false) {
    if (!isRememberMe) {
      sessionStorage.setItem(LocalStorageKeys.Email, key);
    } else {
      localStorage.setItem(LocalStorageKeys.Email, key);
    }
  }

  clear() {
    this.clearAllExcept([LocalStorageKeys.ApiKey, LocalStorageKeys.Localization, LocalStorageKeys.OptionData, LocalStorageKeys.WordingData]);
    sessionStorage.clear();
  }

  clearAllExcept(excepts: string[]) {
    const localStorageKeys = Object.values(LocalStorageKeys);
    localStorageKeys.forEach(key => {
      if (!excepts.includes(key)) {
        localStorage.removeItem(key);
      }
    });
  }

  getTemporaryPasswordCode() {
    return localStorage.getItem(LocalStorageKeys.TemporaryPasswordCode);
  }

  getWordingSources() {
    return localStorage.getItem(LocalStorageKeys.WordingSources);
  }

  setWordingSources(wordingSource: ILanguageModel[]) {
    localStorage.setItem(LocalStorageKeys.WordingSources, JSON.stringify(wordingSource));
  }

  getWordingData() {
    return localStorage.getItem(`${LocalStorageKeys.WordingData}_${packageInfo.version}`);
  }

  setWordingData(wordingData: any) {
    Object.keys(localStorage)
      .filter(function (k) {
        return k.startsWith(LocalStorageKeys.WordingData);
      })
      .forEach(function (k) {
        localStorage.removeItem(k);
      });

    localStorage.setItem(`${LocalStorageKeys.WordingData}_${packageInfo.version}`, JSON.stringify(wordingData));
    this.$wordingData.next(wordingData);
  }

  setTemporaryPasswordCode(key: string) {
    localStorage.setItem(LocalStorageKeys.TemporaryPasswordCode, key);
  }

  getOptionData() {
    const optionDataStr = localStorage.getItem(LocalStorageKeys.OptionData);
    if (optionDataStr) {
      return JSON.parse(optionDataStr) as IDataOption;
    }
    return null;
  }

  setOptionData(optionData: IOptionDataModel) {
    const acceptDataOption = optionData.sections[0]?.options[0];
    const otherDataSection = optionData.sections[0]?.childSections.find(c => c.name.toLowerCase() === 'other data');
    const googleOption = otherDataSection?.options.find(o => o.name.toLowerCase() === 'google analytics');
    const videoOption = otherDataSection?.options.find(o => o.name.toLowerCase() === 'video analytics');

    let _dataOption: IDataOption = {
      acceptData: {
        id: acceptDataOption?.optionId,
        title: acceptDataOption?.name,
        description: acceptDataOption?.optionsWordings.find(w => w.key === 'option-description')?.textValue ?? '',
        details: optionData?.sections[0].sectionWording.find(w => w.key === 'section-read-more-1')?.textValue ?? '',
      },
      otherData: {
        title: otherDataSection?.name ?? '',
        description: otherDataSection?.sectionWording.find(w => w.key === 'section-description')?.textValue ?? '',
        googleOption: {
          id: googleOption?.optionId ?? 0,
          title: googleOption?.name ?? '',
          description: googleOption?.optionsWordings.find(w => w.key === 'option-read-more-1')?.textValue ?? '',
        },
        videoOption: {
          id: videoOption?.optionId ?? 0,
          title: videoOption?.name ?? '',
          description: videoOption?.optionsWordings.find(w => w.key === 'option-read-more-1')?.textValue ?? '',
        },
      },
    };
    localStorage.setItem(LocalStorageKeys.OptionData, JSON.stringify(_dataOption));
    this.$dataOption.next(_dataOption);
  }

  isRtlLanguage(languageCode: string) {
    return languageCode === 'AR';
  }

  setLocalization(localization: ILocalizationModel) {
    let _localization = Object.assign(localization);
    _localization.currentCountryCode.toUpperCase();
    _localization.currentLanguageCode.toUpperCase();

    Object.keys(localStorage)
      .filter(function (k) {
        return k.startsWith(LocalStorageKeys.Localization);
      })
      .forEach(function (k) {
        localStorage.removeItem(k);
      });

    localStorage.setItem(`${LocalStorageKeys.Localization}_${packageInfo.version}`, JSON.stringify(localization));
    this.$localization.next(localization);
  }

  getLocalization(): ILocalizationModel | null {
    const savedLocalization = localStorage.getItem(`${LocalStorageKeys.Localization}_${packageInfo.version}`);
    return savedLocalization != null ? (JSON.parse(savedLocalization) as ILocalizationModel) : null;
  }

  private getSessionOrLocalStorage(key: string) {
    let result = sessionStorage.getItem(key);

    if (!result) {
      return localStorage.getItem(key);
    }

    return result;
  }
}
