import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader: any = {};
    const apiKey = this.localStorageService.getApiKey();
    this.addHeaderKeys(authHeader, apiKey);
    return next.handle(req.clone({ setHeaders: authHeader }));
  }

  addHeaderKeys(authHeader: any, apiKey: string | null) {
    if (apiKey) {
      authHeader['ApiKey'] = apiKey;
    }
  }
}
