export enum LegalTypes {
  FAQ = 1,
  PrivacyPolicy = 2,
  TermsOfService = 3,
  InstructionManual = 4,
  OptIn = 5,
  TermsOfUse = 6,
  ProgramTerms = 7,
  VerificationFailed = 8,
  VerificationSuccess = 9,
}
