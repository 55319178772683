import { getAPIUrl, getUnleashClientKey, getUnleashUrl } from './environment.provider';

export const environment = {
  API_URL: getAPIUrl(),
  MASTER_API_KEY: '42-5-1d06c',
  DEFAULT_COUNTRY: 'US',
  unleashUrl: getUnleashUrl(),
  unleashClientKey: getUnleashClientKey(),
  unleashAppName: 'ridevu',
  unleashEnvironment: 'production'
};
