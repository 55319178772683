export enum LocalStorageKeys {
  MqttReceiverEnabled = 'mqttReceiverEnabled',
  Email = 'email',
  RefreshToken = 'refreshToken',
  SessionExpiry = 'sessionExpiry',
  ExistingLanguageData = 'existingLanguageData',
  LanguageJson = 'languageJson',
  ApiKey = 'apiKey',
  Session = 'session',
  ConsumerId = 'consumerId',
  ConsumerDeviceId = 'consumerDeviceId',
  ProfileName = 'profileName',
  DeviceId = 'deviceId',
  Birthday = 'birthday',
  SelectedAudioLanguage = 'selectedAudioLanguage',
  SelectedSubtitleLanguage = 'selectedSubtitleLanguage',
  LanguageOverride = 'LanguageOverride',
  RegionOverride = 'RegionOverride',
  EnvironmentOverride = 'EnvironmentOverride',
  featuredPlaylists = 'featuredPlaylists',
  HomePlaylists = 'playlist_home',
  Legals = 'legals',
  AcceptOtherData = 'acceptOtherData',
  AccountDetails = 'accountDetails',
  IdleExpiry = 'idleExpiry',
  TemporaryPasswordCode = 'temporaryPasswordCode',
  OptionData = 'optionData',
  WordingSources = 'wordingSources',
  WordingData = 'wordingData',
  Localization = 'localization',
}
