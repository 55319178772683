import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { ErrorMessages } from '@d-constants/errror-code.constants';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'app/shared/services/account.service';
import { USER_BASEURL_V6 } from '@d-utils/base-urls';
import { CODE_OK, CODE_OK_END, CODE_USER_NOT_VERIFIED } from '@d-utils/common-util';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandleInterceptorService implements HttpInterceptor {
  whitelistedUrl: any[] = [`${USER_BASEURL_V6}/voucher/`];
  sessionErrorCodes: number[] = [20008, 20014, 40001, 40011];

  constructor(
    private toastr: ToastrService,
    private accountService: AccountService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.handleErrorCodeToShowMessage(event);
        }
      }),
      catchError((err: any) => {
        this.handleErrorCodeToShowMessage({
          ok: true,
          url: location.href,
          body: {
            errorCode: err.error.errorCode,
            responseCode: err.error.responseCode,
          },
        });

        return throwError(() => err);
      })
    );
  }

  handleErrorCodeToShowMessage(event: any) {
    const errorCode = event.body?.errorCode;
    const responseCode = event.body?.responseCode;
    const messageCode = errorCode && errorCode !== 0 ? errorCode : responseCode;
    if (event.ok && messageCode) {
      if (this.sessionErrorCodes.includes(messageCode)) {
        this.toastr.error(
          `<div class='toast-message'>Your session is invalid, please log in again! </br> Error Code: ${messageCode}<div>`,
          undefined,
          { enableHtml: true }
        );
        this.accountService.signOut();
        return;
      }

      if (
        !this.whitelistedUrl.filter(w => event.url.startsWith(w)).length &&
        (messageCode < CODE_OK || messageCode > CODE_OK_END || messageCode === CODE_USER_NOT_VERIFIED)
      ) {
        const responseMessage = ErrorMessages[messageCode];
        this.toastr.error(`<div class='toast-message'>${responseMessage} </br> Error Code: ${messageCode}<div>`, undefined, { enableHtml: true });
      }
    }
  }
}
