import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ISignInResponseModel } from '@d-models/account.model';
import { CODE_OK_END, CODE_OK, RENEW_TIME, TEMPORARY_PASSWORD_OK, CODE_USER_NOT_VERIFIED } from '@d-utils/common-util';
import { AccountService } from 'app/shared/services/account.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  isShowPassword = false;
  isRememberMe = false;

  signInForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private accountService: AccountService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  onSubmit() {
    const email: string = this.signInForm.value.email!;
    const password = this.signInForm.value.password!;
    if (!this.signInForm.invalid) {
      this.accountService.signIn(email, password).subscribe((res: ISignInResponseModel) => {
        this.handleSignInSubmitResponse(res);
      });
    }
  }

  handleSignInSubmitResponse(res: ISignInResponseModel) {
    if (res.responseCode >= CODE_OK && res.responseCode <= CODE_OK_END && res.responseCode !== CODE_USER_NOT_VERIFIED) {
      this.localStorageService.$SignedInPopup.next(false);

      if (this.isRememberMe) {
        this.wait(RENEW_TIME).then(() => {
          this.renewFunc(res);
        });
      }
      this.saveSignInInfo(res, this.signInForm.value.email!);

      if (res.responseCode === TEMPORARY_PASSWORD_OK) {
        this.router.navigate(['/authentication/recover-password/EN/US']);
      } else {
        this.router.navigate(['/account-management/my-account/EN/US']);
      }
    }
  }

  saveSignInInfo(res: any, email: string) {
    this.localStorageService.setEmail(email, this.isRememberMe);
    this.localStorageService.setSession(res.session, this.isRememberMe);
    this.localStorageService.setSessionExpiry(res.sessionExpiry, this.isRememberMe);
    this.localStorageService.setRefreshToken(res.refreshToken, this.isRememberMe);
    this.localStorageService.setConsumerId(res.consumerId?.toString() || '', this.isRememberMe);
    this.localStorageService.setConsumerDeviceId(res.consumerDeviceId?.toString() || '', this.isRememberMe);

    if (res.responseCode === TEMPORARY_PASSWORD_OK) {
      this.localStorageService.setTemporaryPasswordCode(res.temporaryPasswordCode);
    }
  }

  onCloseSignInPopup() {
    this.localStorageService.$SignedInPopup.next(false);
  }

  wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async renewFunc(res: any) {
    const renewResponse = await lastValueFrom(this.accountService.renewSession(res.refreshToken));
    this.saveRenewInfo(renewResponse);
    const session = this.localStorageService.getSession();

    if (session) {
      await this.wait(RENEW_TIME);
      await this.renewFunc(res);
    }
  }

  saveRenewInfo(renewResponse: any) {
    if (renewResponse.session) {
      this.localStorageService.setSession(renewResponse.session, true);
      this.localStorageService.setSessionExpiry(renewResponse.sessionExpiry, true);
      this.localStorageService.setRefreshToken(renewResponse.refreshToken, true);
    }
  }
}
