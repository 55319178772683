import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILegalContentResponseModel } from '@d-models/legal.model';
import { LEGAL_BASEURL_V6, USER_BASEURL_V7 } from '@d-utils/base-urls';
import { LocalStorageService } from './local-storage.service';
import { LegalTypes } from '@d-well-know-types/legal-types.enum';
import { IGetLanguagesResponseModel } from '@d-models/languages.model';
import { IQuestionModel } from '@d-models/question.model';
import { IIssueModel, ITopicModel } from '@d-models/topic.model';
import { IOptionDataModel } from '@d-models/options-data.model';
import { DEFAULT_LANGUAGE_CODE, DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST } from '@d-well-know-types/app.constants';
import { IWordingResponseModel } from '@d-models/wording.model';
import { IBoolResponseModel } from '@d-models/boolean-response.model';
import { LegalsWordingResponse } from '@d-models/legals-wording.model';
import { lastValueFrom } from 'rxjs';

const DEFAULT_LEGAL_TYPE = LegalTypes.FAQ;
@Injectable({
  providedIn: 'root',
})
export class LegalService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getLanguagesBasedOnCountry(countryCode: string) {
    const url = `${LEGAL_BASEURL_V6}/language/${DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST}/${countryCode}/${DEFAULT_LEGAL_TYPE}`;
    return this.httpClient.get<IGetLanguagesResponseModel>(url);
  }

  getLegalContent(legalType: LegalTypes, whiteLableCampaingId: number) {
    const localization = this.localStorageService.getLocalization();
    const url = `${LEGAL_BASEURL_V6}/legals/${whiteLableCampaingId}/${legalType}/${localization?.currentLanguageCode}/${localization?.currentCountryCode}`;
    return this.httpClient.get<ILegalContentResponseModel>(url);
  }

  getCustomerServicePortalId() {
    const url = `${LEGAL_BASEURL_V6}/helpCentre/customerServicePortal/${DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST}`;
    return this.httpClient.get<any>(url);
  }

  getPopularQuestions(customerServicePortalId: number) {
    const localization = this.localStorageService.getLocalization();
    const headers = new HttpHeaders().append('AppLanguage', localization?.currentLanguageCode ?? DEFAULT_LANGUAGE_CODE);
    const url = `${LEGAL_BASEURL_V6}/helpCentre/popularQuestions/${customerServicePortalId}`;
    return this.httpClient.get<IQuestionModel[]>(url, { headers });
  }

  getTopics(customerServicePortalId: number) {
    const localization = this.localStorageService.getLocalization();
    const headers = new HttpHeaders().append('AppLanguage', localization?.currentLanguageCode ?? DEFAULT_LANGUAGE_CODE);
    const url = `${LEGAL_BASEURL_V6}/helpCentre/topics/${customerServicePortalId}`;

    return this.httpClient.get<ITopicModel[]>(url, { headers });
  }

  getAnswerDetails(issueId: number) {
    const url = `${LEGAL_BASEURL_V6}/issue/${issueId}`;
    return this.httpClient.get<IIssueModel>(url);
  }

  isOneTrustCountry(countryCode: string): boolean {
    const oneTrustCountries: string[] = ['US', 'GU', 'PR'];
    return oneTrustCountries.includes(countryCode);
  }

  isCurrentCountryBelongWlc(wlcId: number) {
    const localization = this.localStorageService.getLocalization();
    const url = `${LEGAL_BASEURL_V6}/Country/GetIsCountryActiveForWLC/${wlcId}/${localization?.currentCountryCode ?? DEFAULT_LANGUAGE_CODE}`;
    return this.httpClient.get<IBoolResponseModel>(url);
  }

  async getLegalsWording(wlcId: number) {
    const url = `${LEGAL_BASEURL_V6}/legalsWording/${wlcId}`;
    return await lastValueFrom(this.httpClient.get<LegalsWordingResponse>(url));
  }

  upsertOptionData(callback: any = null) {
    const langCode = this.localStorageService.getLocalization()?.currentLanguageCode.toUpperCase() ?? DEFAULT_LANGUAGE_CODE;
    const url = `${USER_BASEURL_V7}/options/`;
    this.httpClient
      .get<IOptionDataModel>(url, {
        headers: {
          appLanguage: langCode,
        },
      })
      .subscribe((res: any) => {
        if (res) {
          if (callback) 
          { 
            callback(res);
          }
          else {
            this.localStorageService.setOptionData(res);
          }
        }
      });
  }

  async getAppWording() {
    const localization = this.localStorageService.getLocalization();
    const url = `${LEGAL_BASEURL_V6}/legalsWording/${DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST}`;
    const res = await lastValueFrom(this.httpClient.get<IWordingResponseModel>(url));
    const wordingForCurrentLang = res.legalsWording.filter(i => i.languageCode === localization?.currentCountryCode ?? DEFAULT_LANGUAGE_CODE);
    this.localStorageService.setWordingSources(wordingForCurrentLang);
  }
}
