import { Component, OnInit } from '@angular/core';
import { ILocalizationModel } from '@d-models/selected-localization.model';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isShowOneTrustBadge: boolean = false;
  localization!: ILocalizationModel | null;
  oneTrustCountries: string[] = ['US', 'GU', 'PR'];

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.localStorageService.$localization.subscribe(lo => {
      if (lo) {
        this.localization = lo;
      }
      this.localization = this.localStorageService.getLocalization();
      this.checkShowOneTrustBadge();
    });
  }

  checkShowOneTrustBadge() {
    this.isShowOneTrustBadge = this.oneTrustCountries.includes(this.localization?.currentCountryCode ?? '');
  }
}
