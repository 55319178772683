import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
//import { USER_BASEURL_V8 } from '@d-utils/base-urls';
import { DEVICE_BASEURL_V8 } from '@d-utils/base-urls';
import { lastValueFrom } from 'rxjs';
import { IGetUserDevices } from '@d-models/device.model';
import { IBaseResponseModel } from '@d-models/base-response.model';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getHeaders(addSession = false) {
    const session: string = this.localStorageService.getSession()!;

    const headers: any = {
      AppLanguage: 'EN',
      pragma: 'public',
    };
    if (addSession) {
      headers.Session = session;
    }
    return headers;
  }

  async getUserDevices() {
    const url = `${DEVICE_BASEURL_V8}/devices`;

    return await lastValueFrom(
      this.httpClient.get<IGetUserDevices>(url, {
        headers: this.getHeaders(true),
      })
    );
  }

  async signOut(consumerDeviceId: number) {
    const url = `${DEVICE_BASEURL_V8}/devices/${consumerDeviceId}/logout`;

    return await lastValueFrom(
      this.httpClient.put<IBaseResponseModel>(url, null, {
        headers: this.getHeaders(true),
      })
    );
  }

  async signOutAll() {
    const url = `${DEVICE_BASEURL_V8}/devices/logout-all`;

    return await lastValueFrom(
      this.httpClient.put<IBaseResponseModel>(url, null, {
        headers: this.getHeaders(true),
      })
    );
  }
}
