<div class="container header">
  <a class="logo" href="/"><img height="22" width="143" src="assets/logos/header-logo.png" alt="RideVu" /></a>
  <div class="pt-2 pb-2" *ngIf="isShowSignInButton">
    <button
      [appTranslator]="'landing_sign_in | uppercase'"
      class="mt-2 mb-2 p-3 btn btn-primary btn-login text-center d-none d-sm-block"
      (click)="onShowSignedInPopup()"></button>
    <button
      [appTranslator]="'landing_sign_in | uppercase'"
      class="mt-2 mb-2 p-3 btn btn-primary btn-login text-center d-block d-sm-none btn-mobile"
      (click)="onShowSignedInPopup()"></button>
  </div>
  <div class="p-2 d-flex align-items-center menu d-none d-sm-block" *ngIf="isSignedIn">
    <div class="d-flex flex-row justify-content-center">
      <ul class="nav nav-underline" *ngIf="currentRoute">
        <li class="nav-item">
          <a
            [appTranslator]="'my_account'"
            [class]="currentRoute.includes('my-account') ? 'nav-link active' : 'nav-link'"
            [routerLink]="['/account-management/my-account/EN/US']"></a>
        </li>
        <li class="nav-item">
          <a
            [appTranslator]="'transaction_history'"
            [class]="currentRoute.includes('history') ? 'nav-link active' : 'nav-link'"
            [routerLink]="['/transaction/history/EN/US']"></a>
        </li>
        <li class="nav-item">
          <a
            [appTranslator]="'settings_option_legal'"
            [class]="currentRoute.includes('legal-term') ? 'nav-link active' : 'nav-link'"
            [routerLink]="['/legal/legal-term/EN/US']"></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="p-2 d-flex text-right d-block d-sm-none" *ngIf="isSignedIn">
    <a id="collapse-btn" data-bs-toggle="collapse" href="#menu-collapse">
      <i class="rv-icon-menu"></i>
    </a>

    <div class="menu-mobile container collapse" id="menu-collapse">
      <ul class="nav nav-underline">
        <li class="nav-item vw-100 ps-2 pe-2 mt-2">
          <a class="nav-link" [routerLink]="['/account-management/my-account/EN/US']" (click)="onShowHideMenu()" [appTranslator]="'my_account'"></a>
        </li>
        <li class="nav-item vw-100 ps-2 pe-2 mt-2">
          <a class="nav-link" [routerLink]="['/transaction/history/EN/US']" (click)="onShowHideMenu()" [appTranslator]="'transaction_history'"></a>
        </li>
        <li class="nav-item vw-100 ps-2 pe-2 mt-2 mb-2">
          <a class="nav-link" [routerLink]="['/legal/legal-term/EN/US']" (click)="onShowHideMenu()" [appTranslator]="'settings_option_legal'"></a>
        </li>
      </ul>
    </div>
  </div>
</div>
