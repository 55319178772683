import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MasterLayoutComponent } from './master-layout/master-layout.component';
import { HeaderComponent } from './master-layout/header/header.component';
import { FooterComponent } from './master-layout/footer/footer.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LanguagesSelectorComponent } from './master-layout/languages-selector/languages-selector.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthInterceptorService } from '@d-utils/http-interceptors/auth-interceptor.service';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { SpinnerInterceptorService } from '@d-utils/http-interceptors/spinner-interceptor.service';
import { ErrorHandleInterceptorService } from '@d-utils/http-interceptors/error-handle-interceptor.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SignInComponent } from './modules/authentication/sign-in/sign-in.component';
import { SharedModule } from './shared/modules/shared/shared.module';
import { HomeComponent } from './master-layout/home/home.component';
import { IntroduceComponent } from './master-layout/introduce/introduce.component';
import { EmailVerificationSuccessComponent } from './modules/static-page/email-verification-success/email-verification-success.component';
import { EmailVerificationFailedComponent } from './modules/static-page/email-verification-failed/email-verification-failed.component';
// AoT requires an exported function for factories

@NgModule({
  declarations: [
    AppComponent,
    MasterLayoutComponent,
    SignInComponent,
    HeaderComponent,
    FooterComponent,
    LanguagesSelectorComponent,
    SpinnerComponent,
    HomeComponent,
    IntroduceComponent,
    EmailVerificationSuccessComponent,
    EmailVerificationFailedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandleInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
