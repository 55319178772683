<div [class]="masterCssClasses + ' d-flex flex-column '">
  <div class="header-wrapper sticky-top">
    <app-header></app-header>
  </div>
  <div class="page-body">
    <div class="body-inner">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="footer-wrapper sticky-bottom">
    <app-footer *ngIf="country"></app-footer>
  </div>
</div>
<div class="sign-in-page" *ngIf="isShowSignedInPopup">
  <app-sign-in class="app-sign-in"></app-sign-in>
</div>
