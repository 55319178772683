import { environment } from '@d-environments/environment';

// Content API
export const CONTENT_BASEURL_V6 = `${environment.API_URL}/content/v6`;
export const CONTENT_BASEURL_V7 = `${environment.API_URL}/content/v7`;
export const CONTENT_BASEURL_V8 = `${environment.API_URL}/content/v8`;
export const CONTENT_BASEURL_V9 = `${environment.API_URL}/content/v9`;

// User API
export const USER_BASEURL_V6 = `${environment.API_URL}/user/v6`;
export const USER_BASEURL_V7 = `${environment.API_URL}/user/v7`;
export const USER_BASEURL_V8 = `${environment.API_URL}/user/v8`;
export const USER_BASEURL_V9 = `${environment.API_URL}/user/v9`;

// Payment API
export const PAYMENT_BASEURL_V6 = `${environment.API_URL}/payments/v6`;
export const PAYMENT_BASEURL_V7 = `${environment.API_URL}/payments/v7`;
export const PAYMENT_BASEURL_V8 = `${environment.API_URL}/payments/v8`;

// Legal API
export const LEGAL_BASEURL_V6 = `${environment.API_URL}/legals/v6`;
export const LEGAL_BASEURL_V7 = `${environment.API_URL}/legals/v7`;

// Device API
export const DEVICE_BASEURL_V8 = `${environment.API_URL}/user/v8`;
