import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAccountCredit,
  IAccountCreditsExpiration,
  IAccountSubscriptions,
  IForgotPasswordModel,
  IPasswordReset,
  ISignInResponseModel,
  IUpdatePasswordModel,
} from '@d-models/account.model';
import { USER_BASEURL_V6, USER_BASEURL_V7, USER_BASEURL_V8, USER_BASEURL_V9 } from '@d-utils/base-urls';
import { VERSION } from '@d-utils/common-util';
import { LocalStorageService } from './local-storage.service';
import { IRedemptionVoucherResponseModel } from '@d-models/voucher.model';
import { Router } from '@angular/router';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private router: Router
  ) {}

  getHeaders(addSession = false) {
    const session: string = this.localStorageService.getSession()!;

    const headers: any = {
      AppLanguage: 'EN',
      pragma: 'public',
    };
    if (addSession) {
      headers.Session = session;
    }
    return headers;
  }

  signIn(email: string, password: string) {
    const url = `${USER_BASEURL_V8}/login/`;
    const currentDate = new Date();

    return this.httpClient.post<ISignInResponseModel>(url, {
      oAuthToken: '',
      DeviceIdentifier: `Web${currentDate.getTime()}`,
      deviceModel: 'Web',
      softwareVersion: VERSION,
      email: email,
      password: password,
      accessToken: '',
    }, {
      headers: {
        ApiKey: this.localStorageService.getApiKey()!,
        "Content-Type": "application/json",
      }
    });
  }

  hexValueConverter(char: string) {
    const hexValueOf_a = 10;
    const asciiValueOf_a = 97;

    if (RegExp(/[a-z]/i).exec(char)) {
      char = char.toLowerCase();
      return char.charCodeAt(0) - asciiValueOf_a + hexValueOf_a;
    } else {
      return parseInt(char);
    }
  }

  redeemVoucher(voucherCode: string) {
    const url = `${USER_BASEURL_V9}/voucher/${voucherCode}`;

    return this.httpClient.post<IRedemptionVoucherResponseModel>(url, null, {
      headers: {
        ApiKey: this.localStorageService.getApiKey()!,
        AppLanguage: 'EN',
        Session: this.localStorageService.getSession()!,
        Token: ' ',
        voucherCode: voucherCode,
      },
    });
  }

  updatePassword(oldPassword: string, newPassword: string, confirmNewPassword: string) {
    const url = `${USER_BASEURL_V6}/password`;

    return this.httpClient.patch<IUpdatePasswordModel>(
      url,
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      },
      {
        headers: this.getHeaders(true),
      }
    );
  }

  sendTemporaryPassword(email: string) {
    const url = `${USER_BASEURL_V6}/password?email=${email}`;

    return this.httpClient.post<IForgotPasswordModel>(url, null, {
      headers: this.getHeaders(),
    });
  }

  resetTemporaryPassword(passwordReset: IPasswordReset) {
    const url = `${USER_BASEURL_V6}/temporarypasswordcode`;

    return this.httpClient.patch<IUpdatePasswordModel>(url, passwordReset, {
      headers: this.getHeaders(true),
    });
  }

  renewSession(refreshToken: string) {
    const url = `${USER_BASEURL_V6}/session/refresh`;

    return this.httpClient.post<ISignInResponseModel>(
      url,
      {
        refreshToken,
      },
      {
        headers: this.getHeaders(true),
      }
    );
  }

  getAccountCredits() {
    const url = `${USER_BASEURL_V6}/credits`;

    return this.httpClient.get<IAccountCredit>(url, {
      headers: this.getHeaders(true),
    });
  }

  getAccountCreditsExpiration() {
    const url = `${USER_BASEURL_V6}/creditsExpiration`;

    return this.httpClient.get<IAccountCreditsExpiration>(url, {
      headers: this.getHeaders(true),
    });
  }

  getAccountSubscription() {
    const url = `${USER_BASEURL_V7}/info`;

    return this.httpClient.get<IAccountSubscriptions>(url, {
      headers: this.getHeaders(true),
    });
  }

  signOut() {
    const consumerDeviceId = parseInt(this.localStorageService.getConsumerDeviceId() ?? '0');

    this.deviceService.signOut(consumerDeviceId).then((_: any) => {
      this.localStorageService.clear();
      this.router.navigate(['/']);
    }).catch(() => {
      this.localStorageService.clear();
      this.router.navigate(['/']);
    });
  }
}
