import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerHandlerService {
  public numberOfRequests = 0;
  handleManually = false;
  public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  handleRequest(state = 'minus'): void {
    this.numberOfRequests = state === 'plus' ? this.numberOfRequests + 1 : this.numberOfRequests - 1;
    this.showSpinner.next(this.numberOfRequests > 0 || this.handleManually);
  }

  showManually() {
    this.handleManually = true;
    this.showSpinner.next(true);
  }

  hideManually() {
    this.handleManually = false;
    this.showSpinner.next(false);
  }
}
