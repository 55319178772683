import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { FeatureFlagService, FlagEvents } from './feature-flag.service';
import { FF_SIGN_IN } from '@d-constants/feature-flag.constants';
import { AccountService } from './account.service';

export const authGuardGuard: CanActivateFn = (route, state) => {
  const session = inject(LocalStorageService).getSession();
  const router = inject(Router);

  let featureFlagServiceInject = inject(FeatureFlagService);
  featureFlagServiceInject.events$.subscribe((x: FlagEvents): boolean => {
    if (x === FlagEvents.Ready || x === FlagEvents.Update) {
      let flag = featureFlagServiceInject.isEnabled(FF_SIGN_IN);
      
      if (!flag && session) {
        inject(AccountService).signOut();

        return true;
      }
    }

    return false;
  })

  if (session) {
    return true;
  }

  router.navigate(['/']);
  return false;
};
