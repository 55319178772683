<div class="container">
  <div class="p-3 row sign-in">
    <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
      <div class="px-3 position-relative">
        <h3 [appTranslator]="'landing_sign_in | uppercase'"></h3>
        <span class="rv-icon-close" (click)="onCloseSignInPopup()"></span>
      </div>
      <div class="form-group p-3">
        <input type="email" class="form-control" placeholder="Email" formControlName="email" />
        <div
          *ngIf="signInForm.controls.email.touched && (signInForm.controls.email.errors?.['required'] || signInForm.controls.email.errors?.['email'])"
          class="error-message"
          [appTranslator]="'invalid_email'"></div>
      </div>
      <div class="form-group p-3 position-relative">
        <input [type]="isShowPassword ? 'Text' : 'Password'" class="form-control" placeholder="Password" formControlName="password" />
        <a class="input-group-addon position-absolute icon-eye" (click)="isShowPassword = !isShowPassword">
          <i class="rv-icon-eye"></i>
          <div *ngIf="isShowPassword" class="rv-icon-eye-line"></div>
        </a>
        <div
          *ngIf="signInForm.controls.password.touched && signInForm.controls.password.errors?.['required']"
          class="error-message w-100"
          [appTranslator]="'empty_password_field'"></div>
      </div>
      <div class="p-3">
        <button type="submit" class="btn btn-primary btn-rv w-100" [appTranslator]="'landing_sign_in | uppercase'"></button>
      </div>
      <div class="d-flex justify-content-between align-items-center p-3">
        <div class="d-flex flex-row align-items-center cursor-pointer" (click)="isRememberMe = !isRememberMe">
          <div class="pe-2 position-relative check-group">
            <i class="rv-icon-circle-check"></i>
            <div class="position-absolute un-check" *ngIf="!isRememberMe"></div>
          </div>
          <span [appTranslator]="'remember_me_text'"></span>
        </div>
        <a href="/authentication/forgot-password/EN/US" [appTranslator]="'sign_in_forgot_password'"></a>
      </div>
    </form>
  </div>
</div>
