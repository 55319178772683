import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { DEFAULT_LANGUAGE_CODE } from '@d-well-know-types/app.constants';

@Directive({
  selector: '[appTranslator]',
})
export class TranslatorDirective implements OnInit, OnChanges {
  @Input('appTranslator') key!: string;
  langCode!: string;
  wordingData: any = {};

  constructor(
    private el: ElementRef,
    private localStorageService: LocalStorageService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['key']) {
      this.el.nativeElement.textContent = this.translate(this.key);
    }
  }

  ngOnInit(): void {
    this.localStorageService.$wordingData.subscribe(res => {
      this.langCode = this.localStorageService.getLocalization()?.currentLanguageCode ?? DEFAULT_LANGUAGE_CODE;
      this.wordingData = JSON.parse(this.localStorageService.getWordingData() ?? '{}');
      this.el.nativeElement.textContent = this.translate(this.key);
    });
  }

  private translate(key: string): string {
    const keySegments = key.split('|');
    const _key = keySegments[0].trim();
    const isUpperCase = keySegments.length > 1 && keySegments[1].trim().toLowerCase() === 'uppercase';

    const isDefault = keySegments.length > 1 && keySegments[1].trim().toLowerCase() === 'default';

    let word = this.getTranslation(_key);

    if (isDefault && !word) {
      const defaultValue = keySegments[2];
      if (defaultValue.trim()) {
        return defaultValue.trim();
      } else {
        return 'missing_default_value';
      }
    }

    let paramIdx = keySegments.findIndex(x => x.trim() == 'params');
    if (paramIdx == 1 || (paramIdx == 2 && isUpperCase) || paramIdx == 3) {
      let params = keySegments.slice(paramIdx + 1, keySegments.length);

      params.forEach((p, idx) => {
        word = word.replace(`{${idx}}`, p.trim());
      });
    }

    if (word) {
      return isUpperCase ? word.toUpperCase() : word;
    }

    return '';
  }

  getTranslation(key: string) {
    const categories = ['ridevuWording', 'xperiaWording', 'playstationWording', 'entirePlatformWording'];

    for (const category of categories) {
      if (this.wordingData[category] && key.includes(`${category}.`)) {
        return this.wordingData[category][key.split('.')[1]];
      }
    }

    return this.wordingData[key];
  }
}
