export const DEFAULT_LANGUAGE_CODE: string = 'EN';
export const DEFAULT_COUNTRY_CODE: string = 'US';
export const DEFAULT_CUSTOMER_SERVICE_PORTAL_ID: number = 20;
export const DEFAULT_WHITE_LABEL_CAMPAING_BRAVIA_ID: number = 30;
export const DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST: number = 42;
export const DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_MERCEDES: number = 75;
export const DEFAULT_WHITE_LABEL_CAMPAING_XPERIA_ID: number = 38;
export const DEFAULT_WHITE_LABEL_CAMPAING_PLAYSTATION_ID: number = 108;
export const ONE_MEGABYTE = 1024 * 1024; // 1 megabyte in bytes
export const UPLOAD_FILE_SIZE_LIMIT = 10;
export const EMAIL_US_DESCRIPTION_LIMIT = 1000;
export const TRANSACTION_HISTORY_PAGE_SIZE = 10;
export const MANAGE_DEVICE_PAGE_SIZE = 10;
export const SIGN_OUT_TIMEOUT = 2 * 1000; // 2 seconds
