import { LocalStorageKeys } from '@d-constants/local-storage.enum';

function getEnv() {
  let hostname = window?.location?.hostname ?? '';

  const override = localStorage.getItem(LocalStorageKeys.EnvironmentOverride);
  if (override !== undefined && override !== null) {
    hostname = override;
  }

  if (hostname.includes('localhost')) {
    return 'localhost';
  } else if (hostname.includes('staging')) {
    return 'staging';
  } else if (hostname.includes('uat')) {
    return 'uat';
  }

  return 'production';
}

export function getAPIUrl() {
  let env = getEnv();

  switch (env) {
    case 'localhost':
    case 'staging':
      return 'https://selfservice.staging.ridevu.com';
    case 'uat':
      return 'https://selfservice.uat.ridevu.com';
    default:
      return 'https://selfservice.ridevu.com';
  }
}

export function getUnleashUrl() {
  let env = getEnv();

  switch (env) {
    case 'localhost':
    case 'staging':
      return 'https://unleash-proxy.staging.privilegemovies.com/proxy';
    case 'uat':
      return 'https://unleash-proxy.uat.privilegemovies.com/proxy';
    default:
      return 'https://unleash-proxy.privilegemovies.com/proxy';
  }
}

export function getUnleashClientKey() {
  let env = getEnv();

  switch (env) {
    case 'localhost':
    case 'staging':
      return 'Jjt8tXNa83t6i89o9XxNQbGhxvBRB2m4';
    case 'uat':
      return 'oiZwZnLPMVRrCbqdzKD4kAwbWtW9aoue';
    default:
      return 'JfRpqZdrPVvrkmMZdXeeAMVAUJyd3gvT';
  }
}

export function getEnvironmentNameForSupportTicket() {
  let env = getEnv();

  switch (env) {
    case 'localhost':
    case 'staging':
      return 'STG';
    case 'uat':
      return 'UAT';
    default:
      return '';
  }
}
