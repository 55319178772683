import { Component, OnInit } from '@angular/core';
import { ApiKeyService } from './shared/services/api-key.service';
import { LegalService } from './shared/services/legal.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { DEFAULT_COUNTRY_CODE, DEFAULT_LANGUAGE_CODE } from '@d-well-know-types/app.constants';
import { Router } from '@angular/router';
import { ILocalizationModel } from '@d-models/selected-localization.model';
import { ContentService } from './shared/services/content.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private apiKeyService: ApiKeyService,
    private legalService: LegalService,
    private contentService: ContentService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  async ngOnInit() {
    window.addEventListener('load', this.fixFooter);

    await this.apiKeyService.updateApiKey();
    await this.detectCountryAndLanguageChangeFromUrl();
  }

  fixFooter() {
    if (document.getElementsByClassName('footer-wrapper')[0]) {
      let pageBody = document.querySelector('[class^="page-body"]') as HTMLElement;
      let footerWrapper = document.getElementsByClassName('footer-wrapper')[0] as HTMLElement;

      pageBody.style.marginBottom = footerWrapper.clientHeight + 'px';
      footerWrapper.style.position = 'fixed';
      footerWrapper.style.width = '100%';
    }
  }

  isInvalidRoute(
    langCodeFromRoute: string,
    countryCodeFromRoute: string,
    supportedLanguagesForCountryCodeFromRoute: string[],
    localization: ILocalizationModel | null = null
  ) {
    if (supportedLanguagesForCountryCodeFromRoute.length === 0) {
      return false;
    }

    if (!supportedLanguagesForCountryCodeFromRoute.includes(langCodeFromRoute)) {
      return false;
    }

    if (
      localization &&
      localization.supportLanguages.filter(l => langCodeFromRoute !== DEFAULT_LANGUAGE_CODE && l.languageCode === langCodeFromRoute).length > 0 &&
      localization.currentCountryCode !== countryCodeFromRoute
    ) {
      return false;
    }

    return true;
  }

  async detectCountryAndLanguageChangeFromUrl() {
    //ignore if country and language has been selected
    if (window.location.href.split('?')[0].split('/').length < 5) {
      return;
    }

    const urlSegments = window.location.pathname.split('/');

    let countryCode = urlSegments[urlSegments.length - 1].toUpperCase();

    let langCode = urlSegments[urlSegments.length - 2].toUpperCase();

    const localization = this.localStorageService.getLocalization();

    if (!langCode) {
      langCode = localization?.currentLanguageCode ?? DEFAULT_LANGUAGE_CODE;
    }

    let supportedLanguages = localization?.supportLanguages ?? [];
    if (countryCode !== localization?.currentCountryCode) {
      let res = await lastValueFrom(
        this.legalService.getLanguagesBasedOnCountry(countryCode !== '' ? countryCode : localization?.currentCountryCode ?? DEFAULT_COUNTRY_CODE)
      );

      supportedLanguages = res.languages;
    }

    const isValidRoute = this.isInvalidRoute(
      langCode,
      countryCode,
      supportedLanguages.map(l => {
        return l.languageCode ?? '';
      }),
      localization
    );
    if (!isValidRoute) {
      this.router.navigate(['/']);
      return;
    }

    this.localStorageService.setLocalization({
      currentLanguageCode: langCode ?? DEFAULT_LANGUAGE_CODE,
      currentCountryCode: countryCode,
      supportLanguages: supportedLanguages.map(lang => {
        return {
          languageCode: lang.languageCode ?? DEFAULT_LANGUAGE_CODE,
          languageName: lang.languageName ?? DEFAULT_COUNTRY_CODE,
        };
      }),
    });
    this.contentService.getAppWording();
    this.legalService.upsertOptionData();
  }
}
