import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGetCountriesResponseModel } from '@d-models/country.model';
import { LEGAL_BASEURL_V6 } from '@d-utils/base-urls';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private httpClient: HttpClient) {}

  getCountries() {
    const url = `${LEGAL_BASEURL_V6}/country`;
    return this.httpClient.get<IGetCountriesResponseModel>(url);
  }
}
