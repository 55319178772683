export const VERSION = '1.7.2';

// Networking
export const CODE_OK = 10000;
export const TEMPORARY_PASSWORD_OK = 10003;
export const CODE_OK_END = 19999;
export const CODE_USER_NOT_VERIFIED = 10017;

// Renewal after 2 hours for remember me
export const RENEW_TIME = 7200000;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;

//because datepipe cannot automatically convert in firefox
//have to convert to string manually then convert it back to date
export function convertTofullDateTimeStringFormat(date: Date): string {
  const pad = (nr: any, len = 2) => `${nr}`.padStart(len, `0`);
  const newDate = new Date(date);
  //ISO string: YYYY-MM-DDTHH:mm:ss.sssZ
  const fullDateTimeStringFormat: string = `${newDate.getFullYear()}-${pad(newDate.getMonth() + 1)}-${pad(newDate.getDate())} ${pad(
    newDate.getHours()
  )}:${pad(newDate.getMinutes())}:${pad(newDate.getSeconds())}.${pad(newDate.getMilliseconds(), 3)}Z`;
  return fullDateTimeStringFormat;
}
