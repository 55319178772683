import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { CONTENT_BASEURL_V6, LEGAL_BASEURL_V6 } from '@d-utils/base-urls';
import { AppSettingConstant } from '@d-constants/app-setting.constants';
import { IWordingModel } from '@d-models/wording.model';
import { DEFAULT_LANGUAGE_CODE, DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST } from '@d-well-know-types/app.constants';
import { LegalsWordingResponse } from '@d-models/legals-wording.model';
import { forkJoin } from 'rxjs';
import { SpinnerHandlerService } from './spinner-handler.service';
import { CODE_OK } from '@d-utils/common-util';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private spinnersService: SpinnerHandlerService
  ) {}

  getImageBackground() {
    const localization = this.localStorageService.getLocalization();
    const url = `${CONTENT_BASEURL_V6}/image/BBG/`;
    return this.httpClient.get<any>(url, {
      headers: {
        appLanguage: localization?.currentLanguageCode ?? '',
      },
    });
  }

  getAppWording() {
    this.spinnersService.showManually();
    const localization = this.localStorageService.getLocalization();
    const url = `${CONTENT_BASEURL_V6}/appwording/${AppSettingConstant.HideSpinner}`;
    const langCode = localization?.currentLanguageCode.toUpperCase() ?? DEFAULT_LANGUAGE_CODE;

    this.httpClient
      .get<IWordingModel>(url, {
        headers: {
          appLanguage: langCode,
        },
      })
      .subscribe(res => {
        if (res && res.responseCode === CODE_OK) {
          forkJoin({
            privilegeWording: this.getAppWordingFromPrivilege(res.url + `${AppSettingConstant.HideSpinner}`),
            ridevuWording: this.getAppWordingFromLegal(DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST),
          }).subscribe(res => {
            if (res) {
              this.wordingDataBuilder(res.ridevuWording, res.privilegeWording);
            }
          });
        }
      });
  }

  getAppWordingFromPrivilege(url: string) {
    return this.httpClient.get<any>(url);
  }

  getAppWordingFromLegal(wlcId: number) {
    const url = `${LEGAL_BASEURL_V6}/legalsWording/${wlcId}${AppSettingConstant.HideSpinner}`;
    return this.httpClient.get<LegalsWordingResponse>(url);
  }

  wordingDataBuilder(ridevuWording: LegalsWordingResponse, privilegeWording: any) {
    const currentLangCode = this.localStorageService.getLocalization()?.currentLanguageCode ?? DEFAULT_LANGUAGE_CODE;

    privilegeWording.ridevuWording = {};
    ridevuWording.legalsWording
      .filter(l => l.languageCode.toUpperCase() === currentLangCode.toUpperCase())
      .forEach(l => {
        privilegeWording.ridevuWording[l.key] = l.value;
      });

    this.localStorageService.setWordingData(privilegeWording);
    this.spinnersService.hideManually();
  }
}
