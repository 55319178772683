<div class="bg-content-img introduce-page d-none d-md-block">
  <div class="col p-4 introduce-content">
    <ng-container [ngTemplateOutlet]="textContent"></ng-container>
  </div>
</div>
<div class="d-block d-md-none introduce-mobile">
  <div class="bg-content-img"></div>
  <div class="container text-center">
    <ng-container [ngTemplateOutlet]="textContent"></ng-container>
  </div>
</div>
<div class="copy-right">
  <div class="text-center" [appTranslator]="'text_copyright_line_1 | default | Access requires an eligible vehicle and RIDEVU account, and is subject to RIDEVU Terms of Service. Promotional offers may vary by manufacturer. Available movies and content are subject to change without notice and may vary by market.'"></div>
  <div class="text-center" [appTranslator]="'text_copyright_line_2 | default | (C) 2024 Sony Pictures Home Entertainment Inc. All Rights Reserved. MARVEL and all character names: © &amp; TM MARVEL.'"></div>
  <hr class="d-none d-md-block">
  <hr class="d-block d-md-none horizon-mobile">
</div>
<ng-template #textContent>
  <div class="container">
    <div class="col-md-5 text-content">
      <div class="service-title fw-bold" [appTranslator]="'ridevuWording.service_introduce_title'"></div>
      <p class="service-description mt-3 text" [appTranslator]="'ridevuWording.service_introduce_description'"></p>
    </div>
  </div>
</ng-template>
