import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PricePipe } from 'app/shared/pipes/price.pipe';
import { TranslatorDirective } from 'app/shared/directive/translator.directive';
import { TruncatePipe } from 'app/shared/pipes/truncate.pipe';

@NgModule({
  declarations: [PricePipe, TranslatorDirective, TruncatePipe],
  imports: [CommonModule],
  exports: [PricePipe, RouterModule, TranslatorDirective, TruncatePipe],
})
export class SharedModule {}
