import { Component, OnInit,  } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ILegalContentResponseModel } from "@d-models/legal.model";
import { ILocalizationModel } from "@d-models/selected-localization.model";
import { LegalTypes } from "@d-well-know-types/legal-types.enum";
import { LegalService } from "app/shared/services/legal.service";
import { LocalStorageService } from "app/shared/services/local-storage.service";
import { SpinnerHandlerService } from "app/shared/services/spinner-handler.service";

@Component({
    selector: "app-email-verification-failed",
    templateUrl: "./email-verification-failed.component.html",
    styleUrls: ["./email-verification-failed.component.scss"],
})
export class EmailVerificationFailedComponent implements OnInit {
    contentHtml!: string;
    legalText: string = "email-verification-failed";

    constructor(
        private activeRoute: ActivatedRoute,
        private legalService: LegalService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private spinner: SpinnerHandlerService,
    ) {
    }

    ngOnInit(): void {
        this.localStorageService.$localization.subscribe(
            (lo: ILocalizationModel) => {
                if (lo) {
                    this.handleLegalDocument();
                }
            },
        );

        this.router.events.subscribe((event: any) => {
            if (event.routerEvent instanceof NavigationEnd) {
                this.handleLegalDocument();
            }
        });
    }

    handleLegalDocument() {
        const wlcid = +this.activeRoute.snapshot.params["wlcid"];
        this.getLegalDocument(wlcid);
    }

    getLegalDocument(wlcid: number) {
        this.spinner.showManually();
        this.legalService
            .getLegalContent(LegalTypes.VerificationFailed, wlcid)
            .subscribe(res => {
                this.handleGetLegalResponse(res);
                this.spinner.hideManually();
            });
    }

    handleGetLegalResponse(
        res: ILegalContentResponseModel
    ) {
        if (res.legals) {
            this.contentHtml = res.legals.htmlContent;

			// Replace support-us to support-[COUNTRYCODE]
            let country = this.activeRoute.snapshot.params["country"];
            this.contentHtml = this.contentHtml.replaceAll('support-us@', `support-${country.toLowerCase()}@`);
        
            this.contentHtml = this.contentHtml.replaceAll('BRAVIA CORE', 'RIDEVU');
            this.contentHtml = this.contentHtml.replaceAll('sonypicturescore.com', 'ridevu.com');
        } else {
            this.contentHtml = "<i>No data</i>";
        }

        let contentWrapper: HTMLElement = document.getElementById(
            "legal-content-container",
        )!;

        if (contentWrapper) {
            contentWrapper.innerHTML = this.contentHtml;

            let aTags = document.querySelectorAll("#legal-content-container a");
            for (let item of Array.from(aTags)) {
                this.linkCorrector(item);
            }
        }
    }

    linkCorrector(link: Element) {
        const invalidChar = [".", ">", ")", "(", "<", "]", "["];
        let linkAddress = link.getAttribute("href");
        invalidChar.forEach((c) => {
            if (linkAddress?.endsWith(c)) {
                linkAddress = linkAddress
                    .substring(0, linkAddress.length - 1)
                    .trim();
            }

            if (linkAddress?.startsWith(c)) {
                linkAddress = linkAddress
                    .substring(1, linkAddress.length)
                    .trim();
            }
        });
        link.setAttribute("href", linkAddress ?? "");
    }
}
