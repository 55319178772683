export const FF_SIGN_IN = 'ride-vu-sign-in';
export const FF_CONTACT_US_ISSUE_TYPE = 'ride-vu-issue-type';
export const FF_CONTACT_US_SCREENSHOT = 'ride-vu-screenshot';
export const FF_US = 'ride-vu-us';
export const FF_CA = 'ride-vu-ca';
export const FF_GB = 'ride-vu-gb';
export const FF_DE = 'ride-vu-de';
export const FF_IE = 'ride-vu-ie';
export const FF_JP = 'ride-vu-jp';
export const FF_KR = 'ride-vu-kr';
export const FF_JE = 'ride-vu-je';
export const FF_GG = 'ride-vu-gg';
export const FF_IM = 'ride-vu-im';
