import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingConstant } from '@d-constants/app-setting.constants';
import { SpinnerHandlerService } from 'app/shared/services/spinner-handler.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SpinnerInterceptorService implements HttpInterceptor {
  constructor(private spinnerHandlerService: SpinnerHandlerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isHideSpinner = request.url.includes(AppSettingConstant.HideSpinner);
    if (!isHideSpinner) {
      this.spinnerHandlerService.handleRequest('plus');
    }
    return next.handle(request).pipe(finalize(this.finalize.bind(this)));
  }

  finalize = (): void => this.spinnerHandlerService.handleRequest();
}
