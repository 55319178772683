import { Injectable } from '@angular/core';
import { UnleashClient } from 'unleash-proxy-client';
import { Subject } from 'rxjs';
import { environment } from '@d-environments/environment';
import { DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST } from '@d-well-know-types/app.constants';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private unleash = new UnleashClient({
    url: environment.unleashUrl,
    clientKey: environment.unleashClientKey,
    appName: environment.unleashAppName,
    environment: environment.unleashEnvironment
  });

  private readonly eventSubject = new Subject<FlagEvents>();
  readonly events$ = this.eventSubject.asObservable();

  constructor() {
    this.unleash.setContextField('wlcId', DEFAULT_WHITE_LABEL_CAMPAING_RIDEVU_VINFAST.toString());

    this.unleash.on('ready', () => {
      this.eventSubject.next(FlagEvents.Ready);
    });
    this.unleash.on('update', () => {
      this.eventSubject.next(FlagEvents.Update);
    });
    this.unleash.start();
  }

  update() {
    this.unleash.emit('update');
  }

  isEnabled(toggleName: string): boolean {
    return this.unleash.isEnabled(toggleName);
  }
}

export enum FlagEvents {
  Ready = 1,
  Update,
}
