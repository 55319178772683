import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeService } from '../services/theme.service';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { AppSettingConstant } from '@d-constants/app-setting.constants';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { FeatureFlagService, FlagEvents } from 'app/shared/services/feature-flag.service';
import { FF_SIGN_IN } from '@d-constants/feature-flag.constants';
import { SpinnerHandlerService } from 'app/shared/services/spinner-handler.service';
import { AccountService } from 'app/shared/services/account.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  themeMode: string = AppSettingConstant.ThemeMode.Dark;
  isSignedIn = false;
  routeWatcher: any;
  isShowSignInButton = false;
  isUseFlag = false;
  currentRoute!: string;

  constructor(
    private themeService: ThemeService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private spinnerService: SpinnerHandlerService,
    private featureFlagService: FeatureFlagService,
    private accountService: AccountService,
  ) {
    document.getElementsByTagName('html')[0].setAttribute('data-bs-theme', AppSettingConstant.ThemeMode.Dark.toLocaleLowerCase());
  }

  ngOnDestroy(): void {
    if (this.routeWatcher) {
      this.routeWatcher.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.isSignedIn = !!this.localStorageService.getSession();
    this.routeChangeDetector();

    // Show / hide sign in button
    this.featureFlagService.events$.subscribe((x: FlagEvents) => {
      this.spinnerService.showManually();
      if (x === FlagEvents.Ready || x === FlagEvents.Update) {
        this.isShowSignInButton = this.featureFlagService.isEnabled(FF_SIGN_IN);
        this.isUseFlag = this.isShowSignInButton;

        if (!this.isUseFlag && this.isSignedIn) {
          this.accountService.signOut();
        }

        this.isShowSignInButton = this.isUseFlag && !this.isSignedIn && !this.router.url.includes('sign-in') && !this.router.url.includes('recover-password');
      }
      this.spinnerService.hideManually();
    });
  }

  public routeChangeDetector() {
    this.routeWatcher = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd || event instanceof Scroll) {
        this.setvalueWhenRouterChange();
      }
    });
  }

  setvalueWhenRouterChange() {
    this.currentRoute = this.router.url;
    this.isSignedIn = !!this.localStorageService.getSession();
  
    this.isShowSignInButton = this.isUseFlag && !this.isSignedIn && !this.router.url.includes('sign-in') && !this.router.url.includes('recover-password');
  }

  public onSwitchTheme(): void {
    if (this.themeService.current === AppSettingConstant.ThemeMode.Light) {
      this.themeMode = AppSettingConstant.ThemeMode.Dark;
      this.themeService.current = AppSettingConstant.ThemeMode.Dark;

      document.getElementsByTagName('html')[0].setAttribute('data-bs-theme', AppSettingConstant.ThemeMode.Dark.toLocaleLowerCase());
    } else {
      this.themeMode = AppSettingConstant.ThemeMode.Light;
      this.themeService.current = AppSettingConstant.ThemeMode.Light;

      document.getElementsByTagName('html')[0].removeAttribute('data-bs-theme');
    }
  }

  onShowSignedInPopup() {
    this.localStorageService.$SignedInPopup.next(true);
  }

  onShowHideMenu() {
    const el = document.getElementById('collapse-btn') as HTMLElement;
    el?.click();
  }
}
