import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

@Component({
  selector: 'app-master-layout',
  templateUrl: './master-layout.component.html',
  styleUrls: ['./master-layout.component.scss'],
})
export class MasterLayoutComponent implements OnInit, OnDestroy {
  routeWatcher: any;
  country: any;
  masterCssClasses = 'master-page text-white ';
  isShowSignedInPopup = false;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    localStorageService.$SignedInPopup.subscribe((sip: any) => {
      this.isShowSignedInPopup = sip;
    });
  }

  ngOnDestroy(): void {
    if (this.routeWatcher) {
      this.routeWatcher.unsubscribe();
    }
    this.localStorageService.$localization.unsubscribe();
  }

  ngOnInit(): void {
    this.localStorageService.$localization.subscribe(val => {
      this.handleBackground();
    });
  }

  handleBackground() {
    this.country = this.localStorageService.getLocalization();
    if (this.router.url === '/') {
      this.masterCssClasses = 'master-page text-white world-map';
    } else {
      this.masterCssClasses = 'master-page text-white';
    }
  }
}
